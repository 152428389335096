<template>
  <Page color="error" title="Anzeigetafel">
    <div style="height: 80vh">
      <iframe id="frame" :src="src" />

      <v-overlay absolute :value="overlay" @click="overlay = false">
        <v-alert prominent type="error" border="left" class="mx-4">
          {{
            screen.holiday
              ? "Während den Ferien wird die Anzeigetafel nicht bedient"
              : "Die Anzeigetafel ist momentan ausgeschaltet"
          }}
        </v-alert>
      </v-overlay>
    </div>
  </Page>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      screen: {},
      overlay: false,
    };
  },
  computed: {
    src() {
      if (this.isProd()) {
        return "https://screen.gymkirchenfeld.ch";
      }
      return "https://screen2.gymkirchenfeld.ch";
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.screen = await this.apiList({
        resource: "screen/display",
      });
      this.overlay = !this.screen.power;
      this.loading = false;
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
<style>
#frame {
  width: 100%;
  height: 100%;

  border: 1px solid black;
}
</style>
